const outOption = {
  title: "用户管理",
  column: [
    {
      label: "姓名",
      prop: "name",
    },
    {
      label: "角色",
      prop: "roleList",
    },
    {
      label: "联系电话",
      prop: "mobile",
    },
    {
      label: "设备编号",
      prop: "deviceNo",
    },
  ],
};
export default outOption
