import request from '@/utils/request'

// 获取设备分页
export function fetchDeviceList(data) {
  return request({
    url: '/cms/device/page',
    method: 'get',
    params: data
  })
}
export function deptTree(params){
    return request({
        url: `/admin/dept/tree`,
        method: 'get',
        params
    })
}
// 获取设备列表
export function getDeviceList(data) {
  return request({
    url: '/cms/device/deviceList',
    method: 'get',
    params: data
  })
}

// 编辑设备
export function addDevice(data) {
    return request({
        url: '/cms/device',
        method: 'put',
        data: data
    })
}

// 编辑设备
export function delDevice(id) {
    return request({
        url: `/cms/device/${id}`,
        method: 'delete',
    })
}

// 获取设备详情
export function getDevice(id) {
    return request({
        url: `/cms/device/${id}`,
        method: 'get',
    })
}

// 通过设备原始ID获取设备详情
export function getDeviceById(id){
    return request({
      url: `/cms/device/serial/${id}`,
      method: 'get',
      hideLoading: true
    })
  }

// 获取组织树
export function fetchDeptTree(){
    return request({
        url: 'admin/dept/tree',
        method: 'get'
    })
}
//组织管理树状列表
export function deptTreeByScope(params){
    return request({
        url: `/admin/dept/treeByScope`,
        method: 'get',
        params
    })
}
// 获取组织详情
export function getDeptDetail(id){
    return request({
        url: `admin/dept/${id}`,
        method: 'get'
    })
}

// 删除组织
export function delDept(id){
    return request({
        url: `admin/dept/${id}`,
        method: 'delete'
    })
}

// 修改组织
export function putDept(data){
    return request({
        url: `admin/dept`,
        method: 'put',
        data
    })
}

// 添加组织
export function addDept(data){
    return request({
        url: `admin/dept`,
        method: 'post',
        data
    })
}

// 在线视频处理
export function onlineHandle(data){
    return request({
        url: `/cms/task/deviceToOnline`,
        method: 'post',
        data
    })
}

// 关闭视频处理
export function offOnlineHandle(data){
    return request({
        url: `/cms/task/deviceToOnlineOff`,
        method: 'post',
        data
    })
}

// 操作设备
export function handleDeviceControl(data){
    return request({
        url: `cms/device/ptz`,
        method: 'get',
        params: data,
        hideLoading: true
    })
}

// 操作设备
export function getDeviceByDeptIds(ids){
    return request({
        url: `/cms/device/deptToDevices/${ids}`,
        method: 'get',
        hideLoading: true
    })
}

// 操作设备
export function handleDeviceControlFi(data){
    return request({
        url: `/cms/task/control/fi`,
        method: 'get',
        params: data,
        hideLoading: true
    })
}

//关闭设备能力
export function shutFacility(id){
    return request({
        url: `/cms/task/deviceToViidOff/${id}`,
        method: 'get',
    })
}
//打开设备能力
export function openFacility(data){
    return request({
        url: `/cms/task/deviceToViid`,
        method: 'post',
        data
    })
}
//关闭系统能力
export function shutSystem(id){
    return request({
        url: `/cms/task/deviceToOnlineOffOne/${id}`,
        method: 'get',
    })
}
//打开系统能力
export function openSystem(data){
    return request({
        url: `/cms/task/deviceToOnlineOne`,
        method: 'post',
        data
    })
}
