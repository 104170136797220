import request from "@/utils/request";

// 分页查询电子设备角色
export const electronRolePage = (params)=>{
  return request({
    url: "/electron/role/page",
    method: "get",
    params,
  });
}
// 新增电子角色
export const addElectronRole = (data)=>{
  return request({
    url: "/electron/role",
    method: "post",
    data,
  });
}
// 编辑角色
export const editElectronRole = (data)=>{
  return request({
    url: "/electron/role",
    method: "put",
    data,
  });
}
//删除角色
export const delElectronRole = (id)=>{
  return request({
    url: `/electron/role/${id}`,
    method: "delete",
  });
}
