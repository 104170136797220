<template>
  <div class="user-container">
    <Jump :jumpList="jumpList" />
    <NavMenu activeUrl="/setting/application" />
    <div class="user-wrap">
      <div class="user-menu">
        <chilMenu></chilMenu>
      </div>
      <div class="user-main">
        <div class="search" style="padding-left: 40px">
          <div>
            <el-input placeholder="请输入姓名" v-model="search.name" />
          </div>
          <div style="padding-left: 20px">
            <el-input placeholder="请输入设备编号" v-model="search.deviceNo" />
          </div>
          <div style="padding-left: 20px">
            <el-select v-model="search.roleName" placeholder="请选择角色">
              <el-option
                v-for="item in roleList"
                :key="item.roleId"
                :label="item.roleName"
                :value="item.roleName"
              >
              </el-option>
            </el-select>
          </div>
          <div style="padding-left: 20px">
            <cascader
              v-model="search.deptId"
              :name.sync="search.deptName"
              :options="depDataTree"
              :props="{
                checkStrictly: true,
                label: 'name',
                value: 'id',
                emitPath: false,
              }"
              placeholder="请选择部门"
            ></cascader>
          </div>
          <el-button
            class="filter-item"
            type="primary"
            size="default"
            icon="el-icon-search"
            @click="page.currentPage=1;getList(page, search)"
            >搜索
          </el-button>
          <el-button class="filter-item" size="default" @click="reset"
            >清空
          </el-button>
        </div>
        <avue-crud
          ref="crud"
          :option="option"
          :data="list"
          :page.sync="page"
          v-model="form"
          :table-loading="listLoading"
          @on-load="getList"
          @size-change="sizeChange"
          @current-change="currentChange"
          @row-update="update"
          @row-save="create"
          @selection-change="selectionChange"
          @before-open="beforeOpen"
        >
          <template slot="deptIdForm">
            <cascader
              v-model="form.deptId"
              :name.sync="form.deptName"
              :options="depData"
              :props="{
                checkStrictly: true,
                label: 'name',
                value: 'id',
                emitPath: false,
              }"
            ></cascader>
          </template>
          <template slot="workingGroup" slot-scope="scope">
            <div style="display: flex;align-items: center;justify-content: center">
              <span>{{scope.row.workingGroupName}}</span>
              <div :style="{ backgroundColor: scope.row.color,width:'40px',height:'20px',borderRadius: '3px',marginLeft: '10px' }" ></div>
            </div>
          </template>
          <template slot="workingGroupForm">
            <div style="display: flex; align-items: center">
              <cascader
                v-model="form.workingGroup"
                :options="workTree"
                :props="{
                  checkStrictly: true,
                  label: 'name',
                  value: 'id',
                  emitPath: false,
                }"
              ></cascader>
              <div
                :style="{ backgroundColor: workColor }"
                class="bg-color"
              ></div>
            </div>
          </template>
          <template slot="menuLeft">
            <el-button
              type="primary"
              size="small"
              @click="$refs.crud.rowAdd()"
              icon="el-icon-plus"
              v-auth="['electron_person_add']"
            >
              新增
            </el-button>
            <!--<el-button type="primary" size="small"> 附件导入 </el-button>-->
          </template>
          <template slot="menuRight">
            <el-button type="primary" @click="exportXlsx()" size="small">
              导出
            </el-button>
          </template>

          <template slot="menu" slot-scope="scope">
            <el-button
              type="text"
              size="small"
              icon="el-icon-edit"
              v-if="scope.row.deviceNo"
              @click="unbinding(scope.row)"
              >解绑
            </el-button>
            <el-button
              type="text"
              size="small"
              icon="el-icon-edit"
              v-else
              @click="binding(scope.row)"
              >绑定
            </el-button>
            <el-button
              type="text"
              size="small"
              @click="$refs.crud.rowEdit(scope.row, scope.index)"
              icon="el-icon-edit"
              v-auth="['electron_person_edit']"
              >编辑
            </el-button>
            <el-button
              type="text"
              size="small"
              @click="$refs.detail.open(scope.row)"
              icon="el-icon-view"
              v-auth="['elec_user_detail']"
              >详情
            </el-button>
            <el-button
              type="text"
              size="small"
              icon="el-icon-delete"
              v-auth="['electron_person_del']"
              @click="delRow(scope.row)"
              >删除
            </el-button>
          </template>
          <template slot="roleList" slot-scope="{ row }">
            <span v-for="(item, index) in row.roleList" :key="index">
              {{ item.roleName }}
              <span v-if="index !== row.roleList.length - 1">,</span>
            </span>
          </template>
          <template slot="roleListForm">
            <el-select
              multiple
              v-model="form.roleList"
              placeholder="请选择角色"
              value-key="roleId"
              collapse-tags
            >
              <el-option
                v-for="item in roleList"
                :key="item.roleId"
                :label="item.roleName"
                :value="item"
              >
              </el-option>
            </el-select>
          </template>
        </avue-crud>
      </div>
    </div>
    <detail ref="detail" />
    <binding ref="binding" :deviceList="deviceList" @flushed="getList" />
  </div>
</template>

<script>
import Jump from "@/components/jump";
import NavMenu from "../../../setting/navMenu.vue";
import chilMenu from "../../chilMenu.vue";
import detail from "./detail.vue";
import { tableOption } from "./tableOption";
import { exportXlsx } from "../../../../components/processingXlsx";
import outOption from "./outOption";
import {
  addElectronPerson,
  delElectronPerson,
  editElectronPerson,
  electronPersonPage,
  personUnbind,
} from "../../../../api/elec/user";
import { electronRolePage } from "../../../../api/elec/role";
import { electronDevicePage } from "../../../../api/elec/device";
import { deptTree, deptTreeByScope } from "../../../../api/deviceManagement";
import binding from "./binding.vue";
import { workingGroupTree } from "../../../../api/elec/work";
export default {
  name: "user",
  data() {
    return {
      activeUrl: "elecUser",
      outOption,
      jumpList: [
        { name: "首页" },
        { name: "系统管理" },
        { name: "应用管理" },
        { name: "电子设备" },
        { name: "用户管理" },
      ],
      deviceList: [],
      admineleclist: null, //电子设备管理
      elecrolelist: null,
      elecwarnlist: null,
      elecuserlist: null,
      elecclocklist: null,
      search: {},
      depData: [],
      workTree: [],
      listLoading: false,
      option: tableOption,
      form: {},
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 10, // 每页显示多少条,
        isAsc: false, // 是否倒序
      },
      list: [],
      roleList: [],
      selection: [],
      depDataTree: [],
      // deviceList:[]
    };
  },
  computed: {
    workColor() {
      //js树形数据查找
      const id = this.form.workingGroup;
      const recursion = function findNodeById(data, id) {
        // 遍历每个节点
        for (let i = 0; i < data.length; i++) {
          // 如果找到匹配的ID，直接返回该节点
          if (data[i].id === id) {
            return data[i];
          }
          // 如果该节点有子节点，递归调用 findNodeById 函数继续查找
          if (data[i].children) {
            let result = findNodeById(data[i].children, id);
            // 如果找到匹配的ID，直接返回该节点
            if (result) {
              return result;
            }
          }
        }
        // 如果遍历完整个树都没找到匹配的ID，返回null
        return null;
      };
      return recursion(this.workTree, id)?.colour || "";
    },
    exportData() {
      const data = JSON.parse(JSON.stringify(this.selection));
      return data.map((item) => {
        item.roleList = item.roleList
          .map((item) => {
            return item.roleName;
          })
          .join(",");
        console.log(item.roleList);
        return item;
      });
    },
  },
  created() {
    this.getRoleList();
    this.getDeviceList();
    this.getDep();
    this.getDepTree();
    this.getWorkTree();
  },
  mounted() {
    let permissions = this.$store.state.app.permissions;
    permissions.forEach((item) => {
      if (item === "electron_device_view") {
        this.admineleclist = 1;
      }
      if (item === "electron_alert_record_view") {
        this.elecwarnlist = 1;
      }
      if (item === "electron_punch_record_view") {
        this.elecclocklist = 1;
      }
      if (item === "electron_role_view") {
        this.elecrolelist = 1;
      }
      if (item === "electron_person_view") {
        this.elecuserlist = 1;
      }
    });
  },
  methods: {
    getWorkTree() {
      workingGroupTree().then((response) => {
        this.workTree = response.data;
      });
    },
    async unbinding(row) {
      this.$confirm("是否确定解绑已绑定用户?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        const res = await personUnbind({
          id: row.id,
          deviceNo: row.deviceNo,
        });
        if (res.code === 0) {
          this.$message({
            type: "success",
            message: "解绑成功!",
          });
          this.getList();
        }
      });
    },
    async binding(row) {
      this.$refs.binding.show(row);
    },
    async getDep() {
      const res = await deptTreeByScope();
      this.depData = res.data;
    },
    async getDepTree() {
      const res = await deptTree();
      this.depDataTree = res.data;
    },
    async update(form, index, done, loading) {
      const params = {
        ...form,
        workingGroup: {
          id: form.workingGroup,
        },
      };
      const res = await editElectronPerson(params);
      if (res.code === 0) {
        this.$message.success("编辑成功");
        await this.getList();
        done();
      } else {
        this.$message.warning(res.msg);
        loading();
      }
    },
    async create(form, done, loading) {
      const params = {
        ...form,
        workingGroup: {
          id: form.workingGroup,
        },
      };
      const res = await addElectronPerson(params);
      if (res.code === 0) {
        this.$message.success("新增成功");
        done();
      } else {
        this.$message.warning(res.msg);
        loading();
      }
      await this.getList();
    },
    async delRow(row) {
      console.log(row);
      this.$confirm("是否确认删除该数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        const res = await delElectronPerson(row.id);
        if (res.code === 0) {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          this.getList();
        }
      });
    },
    async exportXlsx() {
      if (!this.selection.length) return this.$message.warning("请选择数据");
      this.exportLoading = true;
      const data = this.exportData;
      const res = await exportXlsx(data, this.outOption).finally(
        () => (this.exportLoading = false)
      );
      console.log(res);
      this.$message.success("导出成功！");
    },
    selectionChange(val) {
      this.selection = val;
    },
    tabClick(url) {
      this.$router.push(url);
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
      this.getList();
    },
    currentChange(current) {
      this.page.currentPage = current;
      this.getList();
    },
    reset() {
      this.search = {};
      this.page.currentPage = 1;
    },
    async getRoleList() {
      const res = await electronRolePage({ size: -1 });
      this.roleList = res.data.records;
    },
    async getDeviceList() {
      const res = await electronDevicePage({ size: -1 });
      this.deviceList = res.data.records.map((item) => {
        return {
          label: item.deviceNo,
          value: item.deviceNo,
        };
      });
      this.findObject(this.option.column, "deviceNo").dicData =
        res.data.records.map((item) => {
          return {
            label: item.deviceNo,
            value: item.deviceNo,
          };
        });
    },
    async getList() {
      const res = await electronPersonPage({
        current: this.page.currentPage,
        size: this.page.pageSize,
        ...this.search,
      });
      this.list = res.data.records.map(item=>{
        return {
          ...item,
          color:item.workingGroup?.colour,
          workingGroup:item.workingGroup?.id,
          workingGroupName:item.workingGroup?.name
        }
      });
      this.page.total = res.data.total;
    },
    beforeOpen(done, type) {
      console.log(type)
      this.form.workingGroup = this.form.beforeOpen.id;
      done();
    },
  },
  components: {
    Jump,
    NavMenu,
    detail,
    chilMenu,
    binding,
  },
};
</script>
<style lang="scss">
.el-menu-e {
  height: 670px;
}

.user-container {
  .user-wrap {
    display: flex;
    .user-menu {
      width: 230px;
      flex-shrink: 0;
      padding-top: 20px;
    }
    .user-main {
      background: transparent;
    }
  }

  .user {
    height: 100%;
    &__main {
      .el-card_body {
        padding-top: 0;
      }
    }
  }
  .el-card {
    background-color: transparent;
    border: none;
  }

  .avue-crud {
    background: transparent;
  }
  .avue-crud__menu {
    background: transparent;
  }
  ::v-deep .single-row {
    font-family: "MicrosoftYaHei";
  }
  ::v-deep .double-row {
    background: #0c265a !important;
    color: #ffffff;
    font-size: 14px;
    font-family: "MicrosoftYaHei";
    font-weight: 400;
  }
  .avue-crud .el-table th {
    background: rgb(17, 50, 117);
    color: rgb(163, 214, 255);
    border: none;
  }
  .el-table tr {
    color: #ffffff;
    background-color: #081e49;
  }
  .el-table--striped
    .el-table__body
    tr.el-table__row--striped
    td.el-table__cell {
    background-color: #0c265a;
    border-bottom: 1px solid #1d3b6a;
    border-top: 1px solid #1d3b6a;
  }
  .el-table td.el-table__cell,
  .el-table th.el-table__cell.is-leaf {
    border-bottom: 0px;
  }
  .el-table th.el-table__cell {
    color: #a3d6ff;
    background-color: #113275;
  }
  .el-table--border::after,
  .el-table--group::after,
  .el-table::before,
  .el-table__fixed-right::before,
  .el-table__fixed::before {
    background-color: #1d3b6a;
  }
  .el-table--border,
  .el-table--group {
    border: none;
    margin-top: 20px;
  }
  .el-table__body tr.hover-row.current-row > td.el-table__cell,
  .el-table__body
    tr.hover-row.el-table__row--striped.current-row
    > td.el-table__cell,
  .el-table__body tr.hover-row.el-table__row--striped > td.el-table__cell,
  .el-table__body tr.hover-row > td.el-table__cell {
    background-color: #113275 !important;
  }
  .el-table--border .el-table__cell,
  .el-table__body-wrapper
    .el-table--border.is-scrolling-left
    ~ .el-table__fixed {
    border-right: none;
  }
  .el-pagination__total {
    color: #a3d6ff;
  }
  .el-tag {
    border-color: #1d3b6a;
    background-color: transparent;
  }
  .avue-crud {
    width: 95%;
  }
  .el-button--small.is-circle {
    display: none;
  }
  .el-card__body {
    padding-top: 20px !important;
  }
  .el-scrollbar__wrap {
    overflow: auto;
  }
  .el-radio.is-bordered {
    border: 1px solid rgba(73, 108, 180, 0.36) !important;
  }
  .el-radio {
    color: #a3d6ff;
  }

  // .el-select-dropdown__list{
  //   background: #06193C;
  // }
  .el-pagination__jump {
    color: #a3d6ff;
  }
  .el-table__empty-block {
    background: #0c265a;
  }
  .avue-empty__desc {
    color: #a3d6ff;
  }
  .avue-empty__image img {
    display: none;
  }
  .avue-empty__image {
    height: 0px !important;
  }
}
.el-select-dropdown__list {
  background: #06193c;
}
.el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
  background-color: #0c265a;
}
.search {
  display: flex;
  margin-top: 15px;
  .el-input {
    width: 200px;
    margin-right: 10px;
  }
  .el-range-editor.el-input__inner {
    margin-right: 10px;
    .el-range-input {
      background-color: #06193c;
      color: rgba(163, 214, 255, 0.36) !important;
    }
  }
  .el-date-editor .el-range-separator {
    color: rgba(163, 214, 255, 0.76);
  }
  .el-date-editor .el-range__icon {
    color: rgba(163, 214, 255, 0.76);
  }
}

.avue-dialog .el-tag.el-tag--info {
  color: #a3d6ff;
  background: #0c265a;
  border: 1px solid rgba(73, 108, 180, 0.36) !important;
}
.avue-dialog .el-dialog__header {
  background: url("../../../../assets/dialog_header_bk.png");
  border-bottom: none !important;
  .el-dialog__headerbtn {
    top: 20px !important;
  }
}
.avue-dialog .el-dialog__title {
  color: #a3d6ff !important;
  // padding-left: 45px;
  font-size: 18px;
  font-weight: bold !important;
}
.avue-dialog .el-dialog__body {
  background: #0c265a;
}
.avue-dialog .avue-dialog__footer {
  background: #0c265a;
  border: none !important;
}
.el-collapse-item__wrap {
  background: transparent;
}
.avue-group .el-collapse,
.avue-group .el-collapse-item__wrap {
  border-color: transparent;
}
.avue-form__group--flex {
  .el-form-item__label {
    color: #a3d6ff;
  }
}

::-webkit-scrollbar {
  width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 5px !important;
}
::-webkit-scrollbar-thumb {
  border-radius: 2px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #0a4696;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  background: #0c265a;
}

</style>
<style lang="scss" scoped>
.bg-color {
  width: 37px;
  height: 32px;
  //background-color: rgb(255, 0, 0);
  position: absolute;
  right: -138px;
  border-radius: 2px;
}
</style>
