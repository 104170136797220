/* 是否手机号码*/
function validatePhone(rule, value, callback) {
  // const reg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
  const reg =
    /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/;
  if (value === "" || value === undefined || value == null) {
    callback();
  } else {
    if (!reg.test(value) && value !== "") {
      callback(new Error("请输入正确的电话号码"));
    } else {
      callback();
    }
  }
}
export const tableOption = {
  border: true,
  stripe: true,
  menuAlign: "center",
  selection: true,
  tip: false,
  labelWidth: 140,
  searchMenuSpan: 6,
  align: "center",
  editBtn: false,
  delBtn: false,
  addBtn: false,
  menuWidth:300,
  column: [
    {
      fixed: true,
      label: "姓名",
      prop: "name",
      span: 24,
      rules: [
        {
          required: true,
          message: "请输入姓名",
          trigger: "blur",
        },
      ],
    },
    {
      fixed: true,
      label: "性别",
      type: "select",
      dicData: [
        {
          label: "男",
          value: "男",
        },
        {
          label: "女",
          value: "女",
        },
      ],
      prop: "gender",
      hide: true,
      rules: [
        {
          required: true,
          message: "请选择性别",
          trigger: "blur",
        },
      ],
    },
    {
      fixed: true,
      label: "角色",
      prop: "roleList",
      span: 24,
      rules: [
        {
          required: true,
          message: "请选择角色",
          trigger: "blur",
        },
      ],
    },
    {
      label:'所属部门',
      prop:'deptId',
      span: 24,
      hide:true,
      rules: [{
        required: true,
        message: "请选择所属部门",
        trigger: "blur"
      }]
    },
    {
      label:'所属部门',
      prop:'deptName',
      addDisplay: false,
      editDisplay:false,
    },
    {
      label:'用户分组',
      prop:'workingGroup',
      rules: [{
        required: true,
        message: "请选择用户分组",
        trigger: "blur"
      }]
    },
    {
      fixed: true,
      label: "联系电话",
      prop: "mobile",
      span: 24,
      rules: [
        { validator: validatePhone, trigger: ["blur", "change"] },
        {
          required: true,
          message: "请输入联系电话",
          trigger: "blur",
        },
      ],
    },
    {
      fixed: true,
      label: "紧急联系人1",
      prop: "emergencyContact1",
      hide: true,
      span: 24,
      rules: [
        {
          required: true,
          message: "请输入紧急联系人1",
          trigger: "blur",
        },
      ],
    },
    {
      fixed: true,
      label: "紧急联系人电话1",
      prop: "emergencyContactNumber1",
      hide: true,
      span: 24,
      rules: [
        { validator: validatePhone, trigger: ["blur", "change"] },
        {
          required: true,
          message: "请输入紧急联系人电话1",
          trigger: "blur",
        },
      ],
    },
    {
      fixed: true,
      label: "紧急联系人2",
      prop: "emergencyContact2",
      hide: true,
      span: 24,
      rules: [
      ],
    },
    {
      fixed: true,
      label: "紧急联系人电话2",
      prop: "emergencyContactNumber2",
      hide: true,
      span: 24,
      rules: [
        { validator: validatePhone, trigger: ["blur", "change"] },
      ],
    },
    {
      fixed: true,
      label: "住址",
      prop: "residence",
      placeholder:'具体到门牌号',
      hide: true,
      span: 24,
      rules: [
        {
          required: true,
          message: "请输入住址",
          trigger: "blur",
        },
      ],
    },
    {
      fixed: true,
      label: "设备编号",
      type:'select',
      filterable:true,
      prop: "deviceNo",
      span: 24,
    },
  ],
};
