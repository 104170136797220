<template>
  <el-dialog title="绑定" width="40%" :visible.sync="visible" @close="hide">
    <el-form :model="form" :rules="rules" ref="ruleForm" label-width="auto">
      <el-form-item label="设备编号" prop="deviceNo">
        <el-select v-model="form.deviceNo" placeholder="请选择设备编号">
          <el-option :label="item.label" :value="item.value" v-for="item in deviceList" :key="item.value"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="hide">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import {editElectronPerson} from "../../../../api/elec/user";

export default {
  props:{
    deviceList:{
      type:Array,
      default:()=>[]
    }
  },
  data(){
    return {
      visible:false,
      form:{},
      rules:{
        deviceNo:{
          required:true,
          message: '请选择设备编号',
          trigger: 'blur'
        }
      },
    }
  },
  methods:{
    show(row) {
      this.form = {...row}
      this.visible = true
    },
    hide() {
      this.visible = false
    },
    submit(){
      this.$refs['ruleForm'].validate(async(valid) => {
        if (valid) {
          const res = await editElectronPerson(this.form);
          if (res.code === 0) {
            this.$message.success("绑定成功");
            this.$emit('flushed')
            this.hide()
          } else {
            this.$message.warning(res.msg);
          }
        } else {
          return false
        }
      })}
  }
}
</script>
<style lang="scss" scoped>
@import "@/views/avue-table.scss";
</style>
