import request from "@/utils/request";
// 工作组管理树状列表
export const workingGroupTree = (params) => {
  return request({
    url: "/electron/workingGroup/tree",
    method: "get",
    params,
  });
};
//新增工作组
export const addWorkingGroup = (data) => {
  return request({
    url: "/electron/workingGroup",
    method: "post",
    data,
  });
};
// 编辑工作组
export const editWorkingGroup = (data) => {
  return request({
    url: "/electron/workingGroup",
    method: "put",
    data,
  });
};
// 删除工作组
export const deleteWorkingGroup = (id) => {
  return request({
    url: `/electron/workingGroup/${id}`,
    method: "delete",
  });
};
//工作人员地图列表
export const workingGroupMap = (params)=>{
  return request({
    url: `/electron/workingGroup/map`,
    method: "get",
    params
  });
}
