import request from "@/utils/request";

// 分页查询电子设备
export const electronDevicePage = (params)=>{
  return request({
    url: "/electron/device/page",
    method: "get",
    params,
  });
}
export const addElectronDevice = (data)=>{
  return request({
    url: "/electron/device",
    method: "post",
    data,
  });
}
export const updateElectronDevice = (data)=>{
  return request({
    url: "/electron/device",
    method: "put",
    data,
  });
}
//获取电子设备音频信息
export const electronAudioPage = (params)=>{
  return request({
    url: "/electron/eleaudio/page",
    method: "get",
    params,
  });
}
//删除电子设备音频信息
export const delElectronAudio = (id)=>{
  return request({
    url: `/electron/eleaudio/${id}`,
    method: "delete",
  });
}
//分页查询指令下发记录
export const instructionDispatch = (params)=>{
  return request({
    url: `/electron/device/instructionDispatch/page`,
    method: "get",
    params
  });
}
//tts文本播报
export const ttsTestPlay = (data)=>{
  return request({
    url: `/electron/device/ttsTestPlay`,
    method: "post",
    data
  });
}
//删除电子设备
export const deleteDevice = (id)=>{
  return request({
    url: `/electron/device/${id}}`,
    method: "delete",
  });
}
// 手动录音
export const manualRecording = (data)=>{
  return request({
    url: `/electron/device/manualRecording`,
    method: "post",
    data
  });
}
export const locationQuery = (data)=>{
  return request({
    url: `/electron/device/locationQuery`,
    method: "post",
    data
  });
}
