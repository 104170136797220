<template>
  <div>
    <el-menu-item
      v-if="item.children && item.children.length === 0"
      :key="item.path"
      :index="item.path"
      v-show="item.show"
      >{{ item.label }}
    </el-menu-item>
    <el-submenu
      :index="item.path"
      v-show="item.show"
      v-else
      :key="item.path"
    >
      <template slot="title">{{ item.label }}</template>
      <template v-for="child in item.children">
        <navigation-item v-if="child.children && child.children.length>0" :key="child.path" :item="child" v-show="child.show"/>
        <el-menu-item v-else :key="child.id" :index="child.path" v-show="child.show">
          {{child.label}}
        </el-menu-item>
      </template>
    </el-submenu>
  </div>
</template>
<script>
export default {
  name: 'NavigationItem',
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
