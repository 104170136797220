<template>
  <div class="menu">
    <el-menu
      :default-active="activeUrl"
      background-color="#00275c"
      text-color="white"
      class="el-menu-e"
      @select="tabClick"
    >
      <navigation-item v-for="(menu, i) in hasRouter" :key="i" :item="menu" />
    </el-menu>
  </div>
</template>
<script>
import navigationItem from "../../components/navigation-item/index.vue";
export default {
  components: {
    navigationItem,
  },
  computed: {
    activeUrl() {
      return this.$route.path;
    },
  },
  data() {
    return {
      hasRouter: [
        {
          label: "电子设备管理",
          path: "/setting/application/device/sys",
          permissions: "setting-application-device-sys",
          children:[],
          show: false,
        },
        {
          label: "打卡记录",
          path: "/setting/application/device/punch",
          permissions: "setting-application-device-punch",
          children:[],
          show: false,
        },
        {
          label: "告警记录",
          path: "/setting/application/device/warning",
          permissions: "setting-application-device-warning",
          children:[],
          show: false,
        },
        {
          label: "角色管理",
          path: "/setting/application/device/role",
          permissions: "setting-application-device-role",
          children:[],
          show: false,
        },
        {
          label: "用户管理",
          path: "/setting/application/device/user",
          permissions: "setting-application-device-user",
          children:[],
          show: false,
        },
        {
          label: "工作管理",
          path: "/setting/application/device/work",
          permissions: "setting-application-device-work",
          children:[],
          show: false,
        },
        {
          label: "监护人",
          path: "/setting/application/device/guardian",
          permissions: "setting-application-device-user",
          children: [],
          show: false,
        },
        {
          label: "危险区域",
          path: "/setting/application/device/dangerous",
          permissions: "setting-application-device-dangerous",
          children: [],
          show: false,
        },
      ],
    };
  },
  mounted() {
    this.generateMenu(this.hasRouter);
  },
  methods: {
    generateMenu(data) {
      let permissions = this.$store.state.app.permissions;
      data.forEach((route) => {
        if (permissions.includes(route.permissions)) {
          route.show = true;
        }
        if (route.children && route.children.length) {
          this.generateMenu(route.children);
        }
      });
    },
    tabClick(url) {
      this.$router.push(url);
    },
  },
};
</script>
<style lang="scss">
::v-deep .el-menu-e {
  height: 670px;
}
.menu {
  width: 230px;
  flex-shrink: 0;
  //padding-top: 20px;
}
</style>
