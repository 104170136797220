import request from "@/utils/request";

// 分页查询电子设备用户
export const electronPersonPage = (params) => {
  return request({
    url: "/electron/person/page",
    method: "get",
    params,
  });
};
// 新增电子设备用户
export const addElectronPerson = (data) => {
  return request({
    url: "/electron/person",
    method: "post",
    data,
  });
};
// 编辑电子设备用户
export const editElectronPerson = (data) => {
  return request({
    url: "/electron/person",
    method: "put",
    data,
  });
};
// 删除电子设备用户
export const delElectronPerson = (id) => {
  return request({
    url: `/electron/person/${id}`,
    method: "delete",
  });
};
//获取人员设备信息
export const electronPersonList = (data) => {
  return request({
    url: `/electron/person/list`,
    method: "post",
    data,
  });
};
//用户解绑设备
export const personUnbind = (data)=> {
  return request({
    url: `/electron/person/unbind`,
    method: "post",
    data,
  });
};
